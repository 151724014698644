
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import {defineComponent, ref } from "vue"
import { hideModalById, listenerHideModal } from "@/core/helpers/dom";
import { getModule } from 'vuex-module-decorators'
import {  Form, Field } from "vee-validate";
import * as Validations from "yup";
import NewSaleModule from "@/store/modules/sales/modules/new-sales";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";


export default defineComponent({
    components:{
        DynamicContentModal,
        Form,
        Field
    },
    props:{
        idSale: {
            type: String
            , required: true
        }
    },
    emits: ['open-modal'],
    setup(props, {emit}) {
        //Propiedades
        const moduleNewSale = getModule(NewSaleModule);
        const formCancelSale = ref();
        
        //Schema
        const validationSchema = Validations.object().shape({
              observation: Validations.string().nullable().default(null).label("observation")
        });

        //METODOS
        const sendCancel = async(data: any) => {
            await moduleNewSale.DISABLE_SALE({
              saleId: props.idSale,
              observation: data.observation
            });
        }

        const submitForm = (data: any) => {
            
            Swal.fire({
            title: '¿Está seguro de cancelar el venta?',
            text: "Se procederá a cancelar la venta para este pedido y no podrá revertir la acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ffb300',
            cancelButtonColor: '#7E829',
            confirmButtonText: 'Si, cancelar',
            cancelButtonText: 'Cancelar',
            }).then(async(result) => {
            if (result.isConfirmed) {
                sendCancel(data);
            }});

            hideModalById("#ModalCancelSale");
        };
        
        const resetForm = () => {
            formCancelSale.value.resetForm();
        }

        return {
            validationSchema
            , formCancelSale

            , submitForm
            , sendCancel
            , resetForm
        }

    },
    methods:{
        closeListener(){
            this.resetForm();
        },
        triggerHidden: function(){
            listenerHideModal("#ModalCancelSale", this.closeListener);
        },
    },
    mounted(){
        this.triggerHidden();
    }
})
